import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  phoneOrEmailInner: {
    border: `1px solid ${theme.colors.bgDark}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  phoneOrEmailTextInput: (props) => ({
    flex: 1,
    appearance: 'none',
    border: 'none',
    outline: 'none',
    padding: [['0.9rem', '1em']],
    fontSize: props.isSafari ? '1.6rem' : '1.4rem',
    lineHeight: '1.143em',
    textAlign: 'left',
  }),
  inputLabel: {
    textAlign: 'left',
    textTransform: 'none',
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: 1.25,
    letterSpacing: '-0.03em',
    paddingBottom: '0.9rem',
    display: 'block',
    position: 'relative',
  },
  inputLabelRequired: {
    '&::after': {
      content: '"*"',
      color: theme.colors.alert,
      marginLeft: '0.3rem',
    },
  },
  codeSelect: {
    maxWidth: 0,
    opacity: 0,
    overflow: 'hidden',
    transition: 'max-width 0.75s, opacity 0.1s',
  },
  codeSelectShow: {
    maxWidth: '10rem',
    opacity: 1,
    overflow: 'visible',
    transition: 'max-width 0.1s, opacity 0.75s',
  },
  select: (props) => ({
    maxWidth: '10rem',
    minWidth: '8rem',
    width: '25%',
    marginRight: '1rem',
    '& &__control': {
      border: 'none',
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
      borderColor: theme.colors.disabled,
      borderRadius: 0,
      fontSize: props.isSafari ? '1.6rem' : '1.4rem',
      color: theme.colors.text,
      height: '100%',
      minHeight: '3.8rem',
      '&--is-focused': {
        borderColor: theme.colors.disabled,
        boxShadow: 'none',
      },
    },
    '& &__value-container': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& &__indicator-separator': {
      display: 'none',
    },
    '& &__dropdown-indicator img': {
      margin: 0,
    },
    '& &__menu': {
      fontSize: props.isSafari ? '1.6rem' : '1.4rem',
      borderRadius: 0,
    },
    '& &__option--is-focused': {
      backgroundColor: theme.colors.gray300,
    },
    '& &__option--is-selected': {
      backgroundColor: theme.colors.primary,
      color: theme.colors.light,
    },
  }),
  errorMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.alert,
    padding: '0.8rem 0',
  },
}))

export default useStyles
