/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Form,
  useFormikContext,
} from 'formik'
import useStyles from './login-form-style'
import {
  Input,
  InputPhone,
  Button,
  Link,
  TokenButton,
} from '../../ui'
import {
  EmailOrPhoneInput,
} from './components'
import { combineClassNames } from '../../helpers'

const LoginForm = ({
  countryCallingCodeOptions,
  quickRegisterMode,
  requiredFields,
  formDisabled,
  showVerificationCodeInput,
  socialAvailable,
  supportedLoginApproaches: supportedLoginApproachesCode,
  onSocialSignIn,
  onRegisterClick,
  onRequestSmsTokenError,
  onRequestSmsTokenSuccess,
  onLoginApproachChange,
}) => {
  const { t } = useTranslation()
  const {
    isSubmitting,
    isValid,
    setFieldValue,
    submitForm,
    values,
  } = useFormikContext()

  const [selectedInputType, setSelectedInputType] = useState()

  // supported auth approaches:
  //  phone_password_v2
  //  phone_and_sms_token
  //  email
  //  email_and_email_token
  const loginApproachMapping = {
    phone_password_v2: {
      inputType: 'phone',
      verificationType: 'password',
    },
    phone_and_sms_token: {
      inputType: 'phone',
      verificationType: 'smsToken',
    },
    email: {
      inputType: 'email',
      verificationType: 'password',
    },
    email_and_email_token: {
      inputType: 'email',
      verificationType: 'emailToken',
    },
  }

  const supportedLoginApproaches = useMemo(() => (
    _.map(supportedLoginApproachesCode, (code) => ({
      code,
      ..._.get(loginApproachMapping, code, {}),
    }))
  ), [supportedLoginApproachesCode])

  const acceptableInputTypes = useMemo(() => (
    _.uniq(
      _.map(supportedLoginApproaches, 'inputType'),
    )
  ), [supportedLoginApproaches])
  const acceptableVerificationTypes = useMemo(() => (
    _.uniq(
      _.map(supportedLoginApproaches, 'verificationType'),
    )
  ), [supportedLoginApproaches])

  const verificationFieldsToDisplay = useMemo(() => (
    _.uniq(
      _.compact(
        _.map(supportedLoginApproaches, ({ inputType, verificationType }) => (
          (
            selectedInputType === inputType
            || _.size(acceptableVerificationTypes) === 1
          ) && verificationType
        )),
      ),
    )
  ), [supportedLoginApproaches, selectedInputType])

  const showSmsToken = useMemo(() => (
    _.includes(verificationFieldsToDisplay, 'smsToken')
  ), [_.clone(verificationFieldsToDisplay)])

  const showEmailToken = useMemo(() => (
    _.includes(verificationFieldsToDisplay, 'emailToken')
  ), [_.clone(verificationFieldsToDisplay)])

  const showPassword = useMemo(() => (
    _.includes(verificationFieldsToDisplay, 'password')
  ), [_.clone(verificationFieldsToDisplay)])

  const handleSubmitForm = () => {
    submitForm()
  }

  useEffect(() => {
    if (_.isEmpty(selectedInputType)) return
    const possibleLoginApproaches = _.filter(
      supportedLoginApproaches,
      { inputType: selectedInputType },
    )
    const loginApproach = _.reduce(
      possibleLoginApproaches,
      (result, { code, verificationType }) => {
        switch (verificationType) {
          case 'password':
            if (!_.isEmpty(_.get(values, 'password'))) {
              result = code
            }
            break;
          case 'smsToken':
          case 'emailToken':
            if (!_.isEmpty(_.get(values, 'token'))) {
              result = code
            }
            break;
          default:
            break;
        }
        return result
      },
      '',
    )
    if (
      !_.isEmpty(loginApproach)
      && _.isFunction(onLoginApproachChange)
    ) {
      onLoginApproachChange(loginApproach)
    }
  }, [values, supportedLoginApproaches, selectedInputType])

  // styles
  const styles = useStyles()

  const translationContext = {
    ...(
      quickRegisterMode ? {
        context: 'quickRegister',
      } : {}
    ),
  }

  return (
    <div className={styles.logIn}>
      <h3 className={styles.title}>{t('screens.login.signIn', translationContext)}</h3>
      { _.some(socialAvailable, ['available', true])
        && (
        <>
          {
            _.map(socialAvailable, (socialMedia) => {
              if (socialMedia.available) {
                return (
                  <Button
                    key={socialMedia.name}
                    facebook
                    text={t('screens.login.facebook')}
                    onClick={() => onSocialSignIn(socialMedia.name)}
                  />
                )
              }
              return null
            })
            }
          <p className={styles.orStyle}>
            -
            {' '}
            {t('screens.login.or')}
            {' '}
            -
          </p>
        </>
        )}
      <Form noValidate>
        <div className={styles.inputs}>
          <EmailOrPhoneInput
            countryCallingCodeOptions={countryCallingCodeOptions}
            acceptableInputTypes={acceptableInputTypes}
            onInputTypeChange={(inputType) => {
              setSelectedInputType(inputType)
            }}
            // onLoginApproachChange={onLoginApproachChange}
          />
          {
            showSmsToken && (
              <>
                <div className={styles.getSmsButtonContainer}>
                  <TokenButton
                    dark
                    disabled={_.isEmpty(values.localPhoneNumber)}
                    phone={`${_.get(values, 'countryCallingCode', '')}${_.get(values, 'localPhoneNumber', '')}`}
                    onSuccess={onRequestSmsTokenSuccess}
                    onError={onRequestSmsTokenError}
                  />
                </div>
              </>
            )
          }
          {
            showEmailToken && (
              <>
                <div className={styles.getSmsButtonContainer}>
                  <TokenButton
                    dark
                    disabled={_.isEmpty(values.email)}
                    email={_.get(values, 'email', '')}
                    onSuccess={onRequestSmsTokenSuccess}
                    onError={onRequestSmsTokenError}
                  />
                </div>
              </>
            )
          }
          {
            showVerificationCodeInput
              ? (
                <Input
                  formik
                  label={t('screens.registration.form.token', { context: showSmsToken ? 'phone' : 'email' })}
                  name="token"
                  required={_.get(requiredFields, 'token', false)}
                />
              )
              : ''
          }
          {
            (showSmsToken || showEmailToken) && showPassword && (
              <p className={styles.orStyle}>
                -
                {' '}
                {t('screens.login.or')}
                {' '}
                -
              </p>
            )
          }
          {
            showPassword && (
              <>
                <Input
                  formik
                  label={t('screens.login.form.password')}
                  placeholder={t('screens.login.form.password', { context: 'placeholder' })}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={_.get(values, 'password', '')}
                />
                <Link gatsbyLink to="/reset-password/" className={styles.forgot}>{t('screens.login.forgot')}</Link>
              </>
            )
          }
        </div>
        <p className={styles.requireStyle}>{t('screens.login.required')}</p>
        <Button
          dark
          disabled={formDisabled || isSubmitting || !isValid}
          text={t('screens.login.buttons.submit', translationContext)}
          onClick={handleSubmitForm}
          className={styles.button}
        />
        {
          !quickRegisterMode && (
            <Button
              border
              text={t('screens.login.buttons.register')}
              onClick={onRegisterClick}
              className={styles.button}
            />
          )
        }
      </Form>
    </div>
  )
}

export default LoginForm
